.header {
  width:100%;
  height:80px;
  line-height:80px;
  background:#FFFFFF;
  text-align:center;

  .header-bar {
    display:none;
  }
  .logo {
    vertical-align:middle;
    display:inline-block;

    .logo-img {
      background:url('../images/logo.svg');
      background-size:cover;
      width:140px;
      height:33px;
      position:relative;
      top:-2px;
    }
  }
  .logo-text {
    font-size:12px;
    line-height:16px;
    display:inline-block;
    vertical-align:middle;
    margin-left:37px;
  }

  nav {
    float:right;
    position:relative;
    padding-right:24px;

    ul {
      margin:0;
      font-family:"Roboto", sans-serif;

      li {
        margin:0 50px 0 0;
        display:inline-block;
        font-size:15px;
        line-height:24px;
        list-style-type:none;
        position:relative;

        a {
          text-decoration:none;
          color:#3D3D3D;

          &:hover {
            color:#000000;
          }
        }
        span {
          color:#000000;
        }

        &.with_submenu {
          span {
            position: relative;
            padding-right: 14px;
            cursor: pointer;

            &::after {
              content: '';
              border-left: 4px solid transparent;
              border-right: 4px solid transparent;
              border-bottom: 0;
              border-top: 4px solid #000000;
              position: absolute;
              top: 8px;
              right: 0;
            }
          }
        }

        ul {
          position: absolute;
          left: 0;
          z-index:10;
          padding: 10px 0 10px 15px;
          border-top: 1px solid #D8D8D8;
          top: 30px;
          display:none;
          background:$buttonColor;
          margin-left:-15px;
          @include bradius(4px);

          li {
            a {
              font-size:15px;
              color:#FFFFFF;
              opacity:0.8;
              white-space: nowrap;

              &:hover {
                opacity:1;
                color:#FFFFFF;
              }
            }
          }
        }
        &.active {
          ul {
            display:inline-block;
          }
        }
      }
    }
  }

  .search-box {
    position:absolute;
    right:0;
    top:0;
    background: #FFFFFF;
    height: 100%;
    padding-left: 20px;

    span {
      width:24px;
      height:24px;
      background:url('../images/search_icon.svg') 50%;
      display:inline-block;
      vertical-align:middle;
      cursor:pointer;
    }
    input {
      display:none;
      outline:0;
      padding:0 10px;
      font-size: 14px;
      font-family: "Roboto", sans-serif;
    }
    &.active {
      input {
        height:36px;
        border: 1px solid $darkBlueColor;
        display:inline-block;

        &:focus {
          border-color:$greyColor;
        }
      }
    }
  }
}