.callback {
    padding:120px 0;
    background: #D7D7D7;
    width:100%;
    text-align:center;

    .callback-title {
        font-family: Roboto, sans-serif;
        font-weight: 500;
        font-size:42px;
        color:#404444;
        margin-bottom:25px;
    }
    .callback-description {
        font-size:20px;
        color:#5F6B81;
        line-height:30px;
        margin-bottom:30px;
    }
    .callback-form {
        font-size:0;

        .callback-form_message {
            font-size: 16px;
            color: $buttonColor;
            font-family: "Roboto", sans-serif;
            margin-bottom:20px;
            display:none;

            &.active {
                display:block;
            }
            &.error {
                color:$errorColor;
            }
        }
        input, .callback-form_button {
            display:inline-block;
            vertical-align:middle;
        }
        input {
            height:60px;
            width:420px;
            max-width:100%;
            -webkit-border-top-left-radius: 4px;
            -webkit-border-bottom-left-radius: 4px;
            -moz-border-radius-topleft: 4px;
            -moz-border-radius-bottomleft: 4px;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            border:0;
            padding:0 30px;
            font-size:18px;
            color:$greyColor;

            &::-webkit-input-placeholder { /* Edge */
                color:#BCBCBC;
            }
            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                color:#BCBCBC;
            }
            &::placeholder {
                color:#BCBCBC;
            }
            &.has-error {
                border:1px solid $errorColor;
            }
        }
        .callback-form_button {
            font-size:20px;
            color:#FFFFFF;
            background:#44C45F;
            -webkit-border-top-right-radius: 4px;
            -webkit-border-bottom-right-radius: 4px;
            -moz-border-radius-topright: 4px;
            -moz-border-radius-bottomright: 4px;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            height:60px;
            line-height:60px;
            text-align:center;
            width:210px;
            cursor:pointer;
        }
    }
}

.breadcrumbs {
    background: #F5F5F5;
    text-align:center;
    padding:95px 0 33px;
    font-family:"Open Sans", sans-serif;

    .breadcrumb-title {
        h1 {
            color: #404444;
            font-size: 62px;
            line-height: 70px;
            font-family: "Roboto", sans-serif;
            font-weight:500;
            margin:0 0 35px;
        }
    }
    .breadcrumb-sub {
        font-size:28px;
        line-height:36px;
        color:#7E91A7;
        margin-bottom:85px;
    }
    .breadcrumb-nav {
        a, span {
            font-size:14px;
            line-height:19px;
            color:#9193A1;
            text-decoration: none;
        }
        span {
            font-weight:600;
        }
        a+a::before, a+span::before {
            content:'/';
            font-weight:normal;
            display:inline-block;
            vertical-align:middle;
            margin: 0 5px;
        }
    }
}

.faq-form {
    margin:80px 0;
    width:100%;
    max-width:770px;
    display:inline-block;

    .faq-form_title {
        font-size:18px;
        line-height:21px;
        font-weight:500;
        font-family:"Roboto", sans-serif;
        color:$greyColor;
        margin-bottom:30px;
        text-align:left;

        .faq-form_message {
            display:none;

            &.active {
                display:block;
                margin-top:10px;
            }
            &.error {
                color: #c44444;
            }
            &.success {
                color: $buttonColor;
            }
        }
    }
    .faq-form_input {
        position:relative;
        margin-bottom:20px;
        font-family:"Roboto", sans-serif;

        input, textarea {
            width:100%;
            padding:15px 20px;
            font-size:16px;
            font-family:"Roboto", sans-serif;
            color:$darkBlueColor;
            border: 1px solid rgba(171, 184, 204, 0.5);
            @include bradius(4px);
            max-width:100%;

            &::-webkit-input-placeholder { /* Edge */
                color:$darkBlueColor;
            }
            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                color:$darkBlueColor;
            }
            &::placeholder {
                color:$darkBlueColor;
            }
        }
        input {
            height:50px;
        }
        textarea {
            height:150px;
        }
        .with-error {
            text-align:left;
            font-size: 14px;
            padding: 5px 20px;
            color: #c44444;
        }
    }
    .faq-form_button {
        margin-top:20px;
        float:left;
    }
}

.contact-widget {
    max-width:580px;
    display:inline-block;
    margin-bottom:120px;

    .contact-widget_title {
        font-size:42px;
        line-height:52px;
        font-family:"Roboto", sans-serif;
        font-weight:500;
        margin-bottom:25px;
        color:$greyColor;
    }
    .contact-widget_text {
        font-family:"Open Sans", sans-serif;
        font-size:20px;
        line-height:30px;
        color:$darkBlueColor;
        margin-bottom:20px;
    }
}