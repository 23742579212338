@media (max-width:991px) {
  .header {
    height:60px;
    line-height:60px;

    .header-bar {
      position: absolute;
      right: 20px;
      top: 21px;
      cursor:pointer;
      display:block;

      div {
        background: $greyColor;
        width: 20px;
        height: 3px;
        margin-bottom: 3px;
      }
    }
    .logo-text {
      display:none;
    }
    nav {
      position:absolute;
      top:60px;
      right:0;
      width:300px;
      background:#FFFFFF;
      padding:20px 0;
      display:none;
      @include shadowbox(-5px 10px 17px -5px rgba(0,0,0,0.4));

      &.active {
        display:block;
      }
      ul {
        padding:0;

        li {
          margin:0;
          display:block;
          padding:0 20px;

          ul {
            position:relative;
            top:0;
            padding:0;
            width:100%;
            background:#FFFFFF;
            margin:0;
            border:0;

            li {
              a {
                color:$greyColor;

                &:hover {
                  color:$buttonColor;
                }
              }
            }
          }
        }
      }
    }
  }
  .footer ul.footer-columns li {
    width:50%;
    margin-bottom:60px;
  }
  .container {
    padding: 0 20px;
  }
  .home-wedoing {
    br {
      display:none;
    }
    .home-wedoing_element {
      min-width:200px;
    }
    .home-wedoing_photo {
      display:none;
    }
    .home-wedoing_floatright {
      float:none;
      width:75%;
      padding:0;
    }
    .home-wedoing_photo__left {
      float: none;
      margin-bottom: 40px;
      width: 100%;
    }
  }

  .services-list ul {
    display:block;
    margin-bottom:30px;

    li {
      width:33%;
      display:inline-block;
      min-width:250px;
      margin-bottom:30px;
    }
  }

  .we-created {
    .we-created_title {
      font-size: 36px;
      line-height: 46px;
    }
    ul li {
      width:50%;
      margin-bottom:50px;
    }
  }

  .portfolio-list .portfolio-list_main .portfolio-list_main__box {
    width:100%;
    font-size:14px;
    line-height:22px;

    .portfolio-list_main__title {
      font-size: 28px;
      line-height: 34px;
    }
  }
  ul.list li {
    width:50%;
  }
  .content, .sidebar {
    width:100%;
    padding: 60px 0 60px 0;
  }
  .content+.sidebar {
    padding-top:0;
  }
  .faq-page {
    .faq-page_author {
      font-size:16px;
      line-height:24px;

      span {
        font-size:12px;
        line-height:20px;
      }
    }
    .faq-page_question {
      font-size:16px;
      line-height:26px;
    }
  }
}
@media (max-width:650px) {
  .breadcrumbs {
    padding: 75px 0 33px;

    .breadcrumb-title {
      h1 {
        font-size: 48px;
        line-height: 58px;
        margin: 0 0 25px;
      }
    }
    .breadcrumb-sub {
      font-size:24px;
      line-height:34px;
      margin-bottom: 55px;
    }
  }
  .home-banner {
    height:600px;
    line-height:600px;

    .home-banner_info {
      .home-banner_info__title {
        font-size: 50px;
        line-height: 58px;
        margin-bottom:10px;
      }

      .home-banner_info__sub {
        font-size: 22px;
        line-height: 30px;
        margin-bottom: 20%;
      }

      .home-banner_info__button {
        display: inline-block;

        a {
          color: #FFFFFF;
        }
      }
    }
  }
  .home-wedoing {
    padding:60px 0;

    .home-wedoing_element {
      width:100%;
    }
    .home-wedoing_photo__left {
      height:300px;
    }
  }
  .box-list_title {
    font-size:34px;
    line-height:40px;

    span {
      font-size:16px;
      margin-top:18px;
      line-height:24px;
    }
  }
  .we-created {
    padding:60px 0;

    .we-created_note {
      font-size: 18px;
      line-height: 20px;
    }
    .we-created_title {
      font-size: 26px;
      line-height: 34px;
    }
    ul li {
      width:50%;
      margin-bottom:50px;
    }
  }
  .footer ul.footer-columns li {
    width:100%;
    margin-bottom:60px;
    padding:0;
  }

  .portfolio-list {
    padding:60px 0;
  }

  ul.list li {
    width: 100%;
    max-width: 370px;
    margin-bottom: 40px;
  }

  .callback {
    .callback-title {
      font-size: 38px;
      margin-bottom: 20px;
    }
    .callback-description {
      font-size: 16px;
      line-height: 26px;
    }
    .callback-form input {
      @include bradius(4px);
      max-width:90%;
    }
    .callback-form .callback-form_button {
      width: 230px;
      margin-top: 20px;
      max-width: 80%;
    }
  }
  .contacts-address {
    margin-top:60px;

    & > div {
      width:100%;
      margin-bottom: 50px;
    }
  }

  .price-title {
    font-size: 32px;
    line-height: 38px;
  }
  .price-box {
    .price-box_title {
      .price-box_right {
        display:none;
      }
    }
    .price-row {
      height: auto;
      line-height: normal;
      padding: 20px 0;
    }
    .price-box_left {
      width: 100%;
      margin-bottom: 5px;
    }
    .price-box_right {
      width: 100%;
      font-weight: 600;
    }
  }
  .faq-simple {
    padding:60px 0;
  }
  .faq-page {
    padding:60px 0;

    .faq-page_question {
      font-size:14px;
      line-height:20px;
    }
  }
  .contact-widget {
    max-width:100%;
    padding:0 20px;
    margin-bottom:60px;

    .contact-widget_title {
      font-size:34px;
      line-height:34px;
    }
    .contact-widget_text {
      font-size:16px;
      line-height:26px;
    }
  }
}
@media (max-width:480px) {
  .breadcrumbs {
    padding: 65px 0 33px;

    .breadcrumb-title {
      h1 {
        font-size: 34px;
        line-height: 44px;
        margin: 0 0 20px;
      }
    }
    .breadcrumb-sub {
      font-size:18px;
      line-height:28px;
      margin-bottom: 45px;
    }
    .breadcrumb-nav {
      a, span {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
  .home-banner {
    height: 400px;
    line-height: 400px;

    .home-banner_info {
      .home-banner_info__title {
        font-size: 34px;
        line-height: 40px;
      }

      .home-banner_info__sub {
        font-size: 18px;
        line-height: 30px;
        margin-bottom: 30%;
      }

      .home-banner_info__button {
        display: inline-block;

        a {
          color: #FFFFFF;
        }
      }
    }
  }
  .we-created {
    .we-created_note {
      font-size: 14px;
    }
    .we-created_title {
      font-size: 18px;
      line-height: 24px;
      margin-bottom:30px;
    }
    ul li {
      width:100%;
    }
  }

  .home-wedoing {
    .home-wedoing_photo__left {
      height:240px;
    }
    .home-wedoing_floatright {
      width:100%;
    }
  }

  ul.list {
    li {
      .image {
        &:after {
          content: '';
          background: url('../images/play.svg');
          background-size:cover;
          position: absolute;
          width: 46px;
          height: 46px;
          left: 50%;
          margin-left: -23px;
          top: 50%;
          margin-top: -23px;
        }
      }
    }
  }
  #contact-map {
    margin-bottom:60px;
  }
  .contact-page .contact-form .contact-form_title {
    font-size: 30px;
    line-height: 40px;
  }
  .price-box {
    .price-box_left, .price-box_right {
      font-size:14px;
      padding:0 30px 0 15px;

      span {
        font-size:12px;
      }
    }
  }
  .price-description {
    font-size:14px;
    margin-bottom:50px;
  }
  .faq-page {
    .answer {
      margin-left:30px !important;
    }
    .faq-page_author {
      padding-left:0;

      &::after {
        display:none;
      }
    }
    .faq-page_question {
      padding-left:0;
    }
  }
  .contact-widget {
    .contact-widget_title {
      font-size:30px;
      line-height:34px;
    }
  }
}