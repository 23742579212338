.footer {
  background: $footerColor;
  padding:68px 0 128px;
  position:relative;
  text-align:center;

  .copyright {
    background: #1A1B1C;
    position:absolute;
    width:100%;
    bottom:0;
    height:80px;
    line-height:80px;
    font-size:16px;
    color: #BEBEBE;
  }
  .logo {
    vertical-align:middle;
    display:inline-block;
    margin-bottom:15px;
    position:relative;
    top:-4px;

    .logo-img {
      background:url('../images/logo-white.svg');
      background-size:cover;
      width:140px;
      height:33px;
      position:relative;
      top:-2px;
    }
  }
  .logo-text {
    font-size:16px;
    line-height:24px;
    color:#9193A1;
  }
  ul.footer-columns {
    font-size:0;
    margin:0;
    padding:0;

    li {
      display:inline-block;
      margin:0;
      list-style-type:none;
      width:25%;
      font-size:16px;
      vertical-align:top;
      padding:15px;

      &:first-child {
        padding-left:0;
      }
      &:last-child {
        padding-right:0;
      }

      .footer-columns_title {
        font-size:16px;
        line-height:24px;
        font-weight:500;
        font-family:"Roboto", sans-serif;
        color:#FFFFFF;
        text-transform: uppercase;
        margin-bottom:24px;

        a {
          color:#FFFFFF;

          &:hover {
            color:$buttonColor;
          }
        }
      }
      span.footer-info {
        font-size:16px;
        line-height:24px;
        color:#9193A1;

        a {
          color:#9193A1;
          text-decoration: none;

          &:hover {
            color:#a9acbf;
          }
        }
      }

      .footer-menu {
        padding:0;
        width:100%;

        li {
          display:block;
          margin:0;
          padding:0;
          font-size:16px;
          width:100%;

          a, & > span {
            border-bottom:1px solid #3F424D;
            height:48px;
            line-height:48px;
            display:block;
            color:#FFFFFF;
            text-decoration: none;

            span {
              line-height:22px;
              display:inline-block;
            }
          }

          & > span, a:hover {
            color:$buttonColor;
            border-color:$buttonColor;
          }
        }
      }
    }
  }

  .footer-text {
    margin-top:52px;
    font-size:16px;
    color:#9193A1;
    line-height:24px;
  }
}