@import 'layouts/_font.scss';
@import 'layouts/_mixins.scss';
@import 'widgets/_widgets.scss';
@import 'layouts/_header.scss';

.text-center {
    text-align:center !important;
}
.fullwidth {
    width:100%;
}
.no-font-size {
    font-size:0;
}
.padding20 {
    padding:0 20px;
}

html {
    height:100%;
}

body {
    min-height: 100%;
    font-family: "Open Sans", sans-serif;
    padding:0;
    margin:0;
}

body * {
    @include boxsize;
}

a {
    text-decoration:none;
}

blockquote {
    border-left: 4px solid #8f9191;
    padding: 0 20px;
    margin: 0 20px;
}

.container {
    width:100%;
    max-width:1170px;
    display:inline-block;
    text-align:left;
}
.rest {
    width:100%;
    clear:both;
}

.button {
    background: $buttonColor;
    @include bradius(4px);
    border:0;
    color:#FFFFFF;
    font-size:20px;
    height:60px;
    padding:0 32px;
    line-height:60px;
    font-family:Roboto, sans-serif;
    cursor:pointer;
    @include transition;
    text-decoration:none;
    display:inline-block;

    &:hover {
        background: #33bf50;
    }
    &.button-white {
        background:#FFFFFF;
        color:$buttonColor;
        border:1px solid $buttonColor;

        &:hover {
            background: $buttonColor;
            color:#FFFFFF;
        }
    }
}

.pagination {
    padding:0;
    margin:20px 0;
    font-size:0;

    li {
        font-size:16px;
        display:inline-block;
        vertical-align:middle;
        text-align:center;

        &.disabled {
            display:none;
        }

        a, span {
            width: 34px;
            height: 34px;
            line-height: 34px;
            color: #404444;
            display: inline-block;
            text-decoration: none;
            border: 1px solid #d8d8d8;
            margin: 0 2px 0 0;
            font-family: "Roboto", sans-serif;
            @include transition;
        }
        &.active, &:hover {
            a, span {
                border-color: #FFFFFF;
                background: #44c45f;
                color: #FFFFFF;
                font-weight: 500;
            }
        }
    }
}

.content, .sidebar {
    display:inline-block;
    vertical-align:top;
    padding:120px 30px 120px 0;
    font-size:16px;
    line-height:24px;
}
.content {
    width:66%;

    .content-city {
        font-family:"Roboto", sans-serif;
        color:$darkBlueColor;
        margin-top:20px;

        span {
            font-weight:500;
            color:$greyColor;
        }
    }
}
.sidebar {
    width:34%;

    .tags {
        font-size:0;

        a {
            font-size:16px;
            font-family:"Roboto", sans-serif;
            padding:0 16px;
            height:40px;
            line-height:40px;
            margin-right:16px;
            margin-bottom:16px;
            display:inline-block;
            vertical-align:top;
            color:$greyColor;
            border:1px solid #D8D8D8;
            @include bradius(4px);
            @include transition;

            &:hover {
                background:$buttonColor;
                color:#FFFFFF;
            }
        }
    }
    .tags+.relateds {
        margin-top: 14px;
    }
    .relateds {
        .relateds-title {
            font-size:24px;
            line-height:28px;
            color:$darkBlueColor;
            font-family:"Roboto", sans-serif;
            font-weight:500;
            margin-bottom:25px;
        }
        ul {
            padding:0;
            margin:0;

            li {
                list-style-type:none;
                margin-bottom:25px;
                height:80px;
                position:relative;

                .relateds-image {
                    width:100px;
                    float:left;
                }
                .relateds-content {
                    padding-left:100px;
                    font-family: "Roboto", sans-serif;

                    span {
                        font-size:16px;
                        line-height:22px;
                        color:$greyColor;
                        position:relative;
                        top:-3px;
                        display:inline-block;
                    }
                    .relateds-content_price {
                        font-size:14px;
                        line-height:normal;
                        color:$darkBlueColor;
                        position:relative;
                        margin-top:10px;
                    }
                }
            }
        }
    }
}

.simple-image {
    img {
        width:100%;
        height:auto;
    }
    iframe {
        width:100%;
        height:300px;
        border:0;
    }
}
.simple-content {
    color:$darkBlueColor;
}
.simple-image + .simple-content {
    margin-top:25px;
}

.site-login {
    padding: 120px 0;
    text-align: center;

    .site-login_block {
        text-align:center;
        width:300px;
        display:inline-block;

        .form-group {
            color: $greyColor;
            font-size:0;
            margin-bottom:15px;

            label, input, .site-login_label {
                display: inline-block;
                vertical-align: middle;
                text-align:left;
                font-size: 16px;
            }
            label, .site-login_label {
                width:130px;
                line-height:36px;
            }
            input[type=text], input[type=password] {
                width:170px;
                height:36px;
                @include bradius(4px);
                border:1px solid #D8D8D8;
                padding:0 10px;
                font-family:"Roboto", sans-serif;
            }
            &.field-loginform-rememberme {
                label {
                    line-height:normal;
                    font-size:13px;
                }
            }
            .button {
                height:36px;
                line-height:36px;
                float:right;
                font-size:16px;
            }
        }
    }
}

.site-error {
    color:$darkBlueColor;
    background: #F5F5F5;
    padding: 120px 0;
    text-align:center;

    h1 {
        color:$greyColor;
    }
    .alert {

    }
}

.faq-page {
    padding:120px 0;
    width:100%;
    max-width:770px;
    display:inline-block;
    text-align:left;

    .faq-page_author {
        padding-left:60px;
        position:relative;
        margin-bottom:16px;
        font-size:18px;
        color:$darkBlueColor;
        line-height:26px;
        font-weight:500;
        font-family:"Roboto", sans-serif;

        span {
            display:block;
            font-size:14px;
            line-height:22px;
            color:#A8A8A8;
        }
        &::after {
            width:48px;
            height:48px;
            position:absolute;
            top:0;
            left:0;
            background:#D7D7D7;
            content:'';
            @include bradius(24px);
        }
        &.answer {
            margin-left:60px;

            &::after {
                background:#FFFFFF url('../images/logo_wt_text.svg') no-repeat 50%;
                background-size:cover;
            }
        }
    }
    .faq-page_question {
        font-size:20px;
        line-height:32px;
        color:$darkBlueColor;
        padding-bottom:55px;
        border-bottom:1px solid #E9EDF5;
        padding-left:60px;

        &.answer {
            margin-left:60px;
        }
    }
    .faq-page_question+.faq-page_author {
        margin-top:30px;
    }
}
.faq-simple {
    line-height: 20px;
    font-family:"Roboto", sans-serif;
    color:$greyColor;
    padding:120px 0;
}
.text-block {
    text-align:center;
    padding: 60px 0;
    line-height: 20px;
    font-family:"Roboto", sans-serif;
    color:$greyColor;

    .container {
        text-align:left;
    }
}
.search-found {
    font-size:16px;
    color:$greyColor;
    font-weight:500;
    font-family:"Roboto", sans-serif;
    margin-top:50px;
}
.search-list {
    margin-bottom:50px;

    li {
        a {
            color:$greyColor;
            font-family:"Roboto", sans-serif;
            font-weight:500;

            &:hover {
                color:$buttonColor;
            }
        }
    }
}
@import 'layouts/_footer.scss';

@import 'layouts/_adap.scss';